import React from 'react'
import { graphql } from "gatsby"
import { 
    Row, Col, Container
} from 'react-bootstrap'

import Layout from '../components/layout'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

const NotFoundPage = () => (
    <NamespacesConsumer>
    {(t, { i18n }) => {
        return (
            <Layout>
                <div id="page404" class="body-page body-bg">
                    <main role="main" className="main d-flex align-items-center">
                        <Container>
                            <Row>
                                <Col className="text-center">  
                                    <h1>404</h1>
                                    <h3>NOT FOUND</h3>
                                </Col>
                            </Row>
                        </Container>
                    </main>
                </div>
            </Layout>
        )
    }}
    </NamespacesConsumer>
)

export default withI18next()(NotFoundPage)

export const query = graphql`
query NotFoundLng($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`
